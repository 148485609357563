<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_v2"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->

        <!--end: Wizard Nav -->

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10">
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <form class="form" id="kt_form" @submit.prevent="handleForm">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <h4 class="mb-10 font-weight-bold text-dark">
                    {{ panelItems.detailsContribution }}
                  </h4>
                  <div class="form-group">
                    <!-- <label
                      >{{ panelItems.projectName
                      }}<span class="star">*</span></label
                    >
                    <b-form-select
                      v-model="form.projectName"
                      :options="projects"
                      class="mb-3"
                    ></b-form-select>
                    <span class="form-text text-muted">{{
                      panelItems.selectProj
                    }}</span> --> 
                    <div v-for="(item,index) in projects" :key="index">
                    <input type="radio" v-model="form.projectName" name="projectName" :value="item"/> {{item}}
                    </div>
                  </div>
                  <b-form-group>
                    <label
                      >{{ panelItems.amount }}<span class="star">*</span></label
                    >
                    <b-form-input
                      type="text"
                      class="form-control form-control-solid form-control-lg"
                      v-model="form.amount"
                      placeholder="Amount"
                      :state="validateState('amount')"
                      aria-describedby="input-amount"
                    ></b-form-input>
                    <span class="form-text text-muted">{{
                      panelItems.contributionAmount
                    }}</span>
                    <b-form-invalid-feedback id="input-amount">
                      <p v-if="!$v.form.amount.required">Amount is required.</p>
                      <p
                        v-if="
                          !$v.form.amount.integer && $v.form.amount.required
                        "
                      >
                        Enter valid amount(in numbers only).
                      </p>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Step 2-->
                <div class="pb-5" data-wizard-type="step-content">
                  <h4 class="mb-10 font-weight-bold text-dark">
                    {{ panelItems.enterPersDetails }}
                  </h4>
                  <div class="row">
                    <div class="col-xl-6">
                      <b-form-group>
                        <label
                          >{{ panelItems.fullName
                          }}<span class="star">*</span></label
                        >
                        <b-form-input
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          id="fullName"
                          v-model="form.donorName"
                          placeholder="Full Name"
                          :state="validateState('donorName')"
                          aria-describedby="input-fullName"
                        ></b-form-input>
                        <span class="form-text text-muted">{{
                          panelItems.enterFullName
                        }}</span>
                        <b-form-invalid-feedback id="input-fullName">
                          <p v-if="!$v.form.donorName.required">
                            Full name is required.
                          </p>
                          <p
                            v-if="
                              !$v.form.donorName.alpha &&
                              $v.form.donorName.required
                            "
                          >
                            Enter valid name.
                          </p>
                          <p
                            v-if="
                              !$v.form.donorName.minLength &&
                              $v.form.donorName.required
                            "
                          >
                            Enter minimum 3 characters.
                          </p>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <label>{{ panelItems.gender }}</label>
                        <b-form-select
                          id="gender"
                          v-model="form.gender"
                          :options="genders"
                          class="mb-3"
                        >
                        </b-form-select>
                        <span class="form-text text-muted">{{
                          panelItems.enterGender
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <b-form-group>
                        <label>{{ panelItems.dob }}</label>
                        <b-form-input
                          id="date"
                          type="date"
                          v-model="form.dob"
                          :state="validateState('dob')"
                          aria-describedby="input-dob"
                        ></b-form-input>
                        <span class="form-text text-muted">{{
                          panelItems.enterDob
                        }}</span>
                        <b-form-invalid-feedback id="input-dob">
                          <p v-if="!$v.form.dob.validDob">
                            Date of birth is not valid.
                          </p>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-xl-6">
                      <b-form-group>
                        <label
                          >{{ panelItems.address
                          }}<span class="star">*</span></label
                        >
                        <b-form-textarea
                          id="address"
                          v-model="form.address"
                          placeholder="Address"
                          :state="validateState('address')"
                          aria-describedby="input-address"
                        ></b-form-textarea>
                        <span class="form-text text-muted">{{
                          panelItems.enterAddress
                        }}</span>
                        <b-form-invalid-feedback id="input-address">
                          <p v-if="!$v.form.donorName.required">
                            Address is required.
                          </p>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <b-form-group>
                        <label
                          >{{ panelItems.city
                          }}<span class="star">*</span></label
                        >
                        <b-form-input
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          id="city"
                          v-model="form.city"
                          placeholder="City"
                          :state="validateState('city')"
                          aria-describedby="input-city"
                        ></b-form-input>
                        <span class="form-text text-muted">{{
                          panelItems.enterCity
                        }}</span>
                        <b-form-invalid-feedback id="input-city">
                          <p v-if="!$v.form.city.required">City is required.</p>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-xl-6">
                      <b-form-group>
                        <label>{{ panelItems.district }}</label>
                        <b-form-input
                          type="text"
                          id="district"
                          v-model="form.district"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          placeholder="District"
                        ></b-form-input>
                        <span class="form-text text-muted">{{
                          panelItems.enterDistrict
                        }}</span>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <b-form-group>
                        <label>{{ panelItems.postCode }}</label>
                        <b-form-input
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          id="postCode"
                          v-model="form.postalCode"
                          placeholder="Postal Code"
                          :state="validateState('postalCode')"
                          aria-describedby="input-postalCode"
                        ></b-form-input>
                        <span class="form-text text-muted">{{
                          panelItems.enterPostCode
                        }}</span>
                        <b-form-invalid-feedback id="input-postalCode">
                          <p v-if="!$v.form.postalCode.integer">
                            Enter valid Postal Code (Numbers only).
                          </p>
                          <p v-if="!$v.form.postalCode.maxLength">
                            Enter valid Postal Code(Cannot exceed 6 digits).
                          </p>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-xl-6">
                      <b-form-group>
                        <label
                          >{{ panelItems.state
                          }}<span class="star">*</span></label
                        >
                        <b-form-select
                          id="state"
                          v-model="form.state"
                          :options="states"
                          class="mb-3"
                          :state="validateState('state')"
                          aria-describedby="input-state"
                        ></b-form-select>
                        <span class="form-text text-muted">{{
                          panelItems.enterState
                        }}</span>
                        <b-form-invalid-feedback id="input-state">
                          <p v-if="!$v.form.state.required">
                            Please select a State.
                          </p>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <b-form-group>
                        <label
                          >{{ panelItems.country
                          }}<span class="star">*</span></label
                        >
                        <b-form-input
                          type="text"
                          :readonly="true"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          id="country"
                          v-model="form.country"
                        >
                        </b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-xl-6">
                      <b-form-group>
                        <label
                          >{{ panelItems.pan
                          }}<span class="star">*</span></label
                        >
                        <b-form-input
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          id="pan"
                          placeholder="PAN"
                          v-model="form.panNo"
                          :state="validateState('panNo')"
                          aria-describedby="input-panNo"
                        ></b-form-input>
                        <span class="form-text text-muted">{{
                          panelItems.enterPan
                        }}</span>
                        <b-form-invalid-feedback id="input-panNo">
                          <p v-if="!$v.form.panNo.required">PAN is required.</p>
                          <p
                            v-if="
                              !$v.form.panNo.validPan && $v.form.panNo.required
                            "
                          >
                            PAN is not valid.
                          </p>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <b-form-group>
                        <label
                          >{{ panelItems.mobileNum
                          }}<span class="star">*</span></label
                        >
                        <b-form-input
                          type="tel"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          v-model="form.phone"
                          id="phone"
                          placeholder="Mobile Number"
                          :state="validateState('phone')"
                          aria-describedby="input-phone"
                        ></b-form-input>
                        <span class="form-text text-muted">{{
                          panelItems.enterMobileNum
                        }}</span>
                        <b-form-invalid-feedback id="input-phone">
                          <p v-if="!$v.form.phone.required">
                            Mobile number is required.
                          </p>
                          <p
                            v-if="
                              !$v.form.phone.integer && $v.form.phone.required
                            "
                          >
                            Mobile number is not valid(Numbers only).
                          </p>
                          <p
                            v-if="
                              !$v.form.phone.minLength && $v.form.phone.required
                            "
                          >
                            Mobile number is not valid.
                          </p>
                          <p
                            v-if="
                              !$v.form.phone.maxLength && $v.form.phone.required
                            "
                          >
                            Mobile number is not valid.
                          </p>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                    <div class="col-xl-6">
                      <b-form-group>
                        <label
                          >{{ panelItems.email
                          }}<span class="star">*</span></label
                        >
                        <b-form-input
                          type="email"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          v-model="form.email"
                          id="email"
                          placeholder="Email"
                          :state="validateState('email')"
                          aria-describedby="input-email"
                        ></b-form-input>
                        <span class="form-text text-muted">{{
                          panelItems.enterEmail
                        }}</span>
                        <b-form-invalid-feedback id="input-email">
                          <p v-if="!$v.form.email.required">
                            Email address is required.
                          </p>
                          <p
                            v-if="
                              !$v.form.phone.email && $v.form.email.required
                            "
                          >
                            Email addtess is not valid.
                          </p>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-6">
                      <b-form-group>
                        <label>{{ panelItems.referredBy }}</label>
                        <b-form-input
                          type="text"
                          class="
                            form-control form-control-solid form-control-lg
                          "
                          id="referredBy"
                          v-model="form.referredBy"
                          placeholder="Referred By"
                        ></b-form-input>
                        <span class="form-text text-muted">{{
                          panelItems.enterReferredBy
                        }}</span>
                      </b-form-group>
                    </div>
                    <div class="col-xl-6">
                      <label>{{ panelItems.paymentGateway }}</label>
                      <b-form-select
                        v-model="form.paymentGateway"
                        :options="paymentGatewaylist"
                        class="mb-3"
                      ></b-form-select>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="text-center">
                    <b-form-group>
                      <input
                        type="checkbox"
                        id="checkbox"
                        v-model="check"
                        name="checkbox"
                        aria-describedby="input-check"
                        v-on:change="termschecked"
                      />
                      I agree to
                      <a style="color: blue" v-b-modal.modal-1>{{
                        panelItems.contTerms
                      }}</a>

                      <div>
                        <b-modal id="modal-1" title="Contribution Terms">
                          <p class="my-4">{{ termsData }}</p>
                        </b-modal>
                      </div>
                      <div style="color: red" v-if="checked == false">
                        <p>{{ panelItems.agreeContTerms }}</p>
                      </div>
                    </b-form-group>
                  </div>
                </div>
                <!--end: Wizard Step 2-->

                <!--begin: Wizard Actions -->
                <div class="d-flex justify-content-center border-top pt-10">
                  <!-- <div class="mr-2"></div> -->
                  <!-- <div class="mt-0"> -->
                  <!-- <button v-on:click="submit" ref="kt_login_signin_submit"
                      class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3">
                      Proceed to Payment
                    </button> -->
                  <div class="text-center">
                    <button
                      class="
                        btn btn-primary
                        font-weight-bold
                        px-9
                        py-4
                        my-3
                        font-size-3
                      "
                      ref="kt_login_signin_submit"
                      :loading="loading"
                      :disabled="loading"
                      color="info"
                      @click="loader = 'loading'"
                      v-on:click="submit"
                    >
                      Proceed to Payment
                    </button>
                  </div>
                  <!-- </div> -->
                </div>
                <!--end: Wizard Actions -->
              </form>
            </div>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";

.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import {
  required,
  integer,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  name: "contribute",
  data() {
    return {
      loader: null,
      loading: false,
      form: {
        projectId: "1",
        projectName: "",
        amount: "",
        donorName: "",
        gender: "",
        dob: "",
        address: "",
        city: "",
        district: "",
        postalCode: "",
        state: "",
        country: "India",
        panNo: "",
        phone: "",
        email: "",
        referredBy: "",
        indianPassport: "Yes",
        projectDescription: "",
        paymentGateway: "",
      },
      check: false,
      checked: true,
      panelItems: [],
      params: [],
      projects: [],
      genders: ["Male", "Female"],
      states: [
        "Andaman and Nicobar islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Ladakh",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttar Pradesh",
        "Uttarkhand",
        "West Bengal",
      ],
      termsData: "",
      paymentGatewaylist: [],
      paymentGatewaylistdata: null,
    };
  },
  created: function () {
    this.getPageContents(), this.alertMessage(), this.getProjectDetails();
  },
  validations: {
    form: {
      amount: {
        required,
        integer,
      },
      donorName: {
        required,
        minLength: minLength(3),
      },
      dob: {
        validDob: (dob) => dob < new Date().toISOString(),
      },
      address: {
        required,
      },
      city: {
        required,
      },
      postalCode: {
        integer,
        maxLength: maxLength(6),
      },
      state: {
        required,
      },
      panNo: {
        required,
        validPan(panNo) {
          return (
            /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/.test(panNo) && panNo.length == 10 // checks for PAN format
          );
        },
      },
      phone: {
        required,
        integer,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      email: {
        required,
        email,
      },
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 3000);
      this.loader = null;
    },
    "form.projectName"() {
      const id = this.projects.indexOf(this.form.projectName);
      this.paymentGatewaylist = [];
      ApiService.get(`/getActivatedPG/${id + 1}`).then(({ data }) => {
        const result = data.paymentGateway;
        this.paymentGatewaylistdata = JSON.parse(JSON.stringify(result));
        result.forEach((element) => {
          this.paymentGatewaylist.push(element.pg_name);
        });
        this.form.paymentGateway = this.paymentGatewaylist[0] || "";
      });
    },
  },
  mounted() {},
  methods: {
    handleForm() {},
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getPageContents() {
      ApiService.get("/organisation").then(({ data }) => {
        this.panelItems = data["panelItems"];
        localStorage.setItem("PanelItems", JSON.stringify(this.panelItems));
      });
    },
    getProjectDetails() {
      ApiService.get("/projectsforDonation").then(({ data }) => {
        const result = data.projectDetails;
        result.forEach((element) => {
          this.projects.push(element.project_name);
        });
        this.form.projectName = this.projects[0] || "";
        //this.projects = data;
        //this.json_data = this.projects.projectDetails;
        //localStorage.setItem("projectItems", JSON.stringify(this.panelItems));
      });
    },
    alertMessage() {
      ApiService.get("/terms").then(({ data }) => {
        let termsContent = data;
        this.termsData = termsContent.termsData;
      });
    },
    termschecked() {
      if (this.check == false) {
        this.checked = false;
        return;
      } else {
        this.checked = true;
      }
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      if (this.check == false) {
        this.checked = false;
        return;
      }
      this.paymentGatewaylistdata.forEach((item) => {
        const data = JSON.parse(JSON.stringify(item));
        if (data.pg_name === this.form.paymentGateway) {
          localStorage.setItem("activatedPgId", data.activatedPGId.toString());
          this.form.activatedPgId = data.activatedPGId.toString();
          //console.log(this.form.activatedPgId);
        }
      });

      ApiService.post("/makeDonation", this.form).then(({ data }) => {
        this.params["order_id"] = data["donation"]["donationId"];
        this.params["amount"] = this.form.amount;
        //this.params["return_url"] = "http://localhost:8080/#/response-handler";
        this.params["customer_id"] = data["donation"]["donorId"];
        this.params["customer_email"] = this.form.email;
        this.params["customer_phone"] = this.form.phone;
        this.params["description"] = this.form.projectDescription;
        this.params["billing_address_first_name"] = this.form.donorName;
        this.params["billing_address_line1"] = this.form.address;
        this.params["billing_address_city"] = this.form.city;
        this.params["billing_address_state"] = this.form.state;
        this.params["billing_address_country"] = this.form.country;
        this.params["billing_address_postal_code"] = this.form.postalCode;
        this.params["product_id"] = this.form.projectId;
        this.params["udf1"] = this.form.projectName;
        this.params["udf2"] = this.form.gender;
        this.params["udf3"] = this.form.panNo;
        // let body = `order_id=${this.params["order_id"]}&amount=${this.params["amount"]}&customer_id=${this.params["customer_id"]}&product_id=${this.params["product_id"]}`;
        ApiService.post("/payments", data["donation"]).then(({ data }) => {
          let links;
          links = data;
          this.webUrl = links.short_url;
          window.open(this.webUrl, "_self");
        });
      });
    },
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
  },
};
</script>
